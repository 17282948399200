import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Card, CardContent } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
    Bot,
    Filter,
    Code,
    Clock,
    Layout,
    Mail,
    Phone,
    GraduationCap,
    Briefcase,
} from "lucide-react";

export default function ConversionFeatures({
    industry,
    industryTitles,
    jobTitle,
    resumeData,
    jobTitles,
}: {
    industry: string;
    industryTitles: string;
    jobTitle: string;
    resumeData: {
        name: string;
        email: string;
        phone: string;
        avatar: string;
        skills: {
            name: string;
            level: number;
        }[];
        experience: {
            title: string;
            company: string;
            period: string;
            description: string;
        }[];
    };
    jobTitles: {
        title: string;
        relevantSkills: string[];
        relevantExperience: string[];
    }[];
}) {
    const [currentJobIndex, setCurrentJobIndex] = useState(0);
    const [highlightedSkills, setHighlightedSkills] = useState<string[]>([]);
    const [relevantExperience, setRelevantExperience] = useState<string[]>([]);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentJobIndex((prevIndex) => (prevIndex + 1) % jobTitles.length);
            setProgress(0);
        }, 10000);

        return () => clearInterval(interval);
    }, [jobTitles]);

    useEffect(() => {
        const progressInterval = setInterval(() => {
            setProgress((prev) => (prev < 100 ? prev + 1 : prev));
        }, 100);

        return () => clearInterval(progressInterval);
    }, [currentJobIndex]);

    useEffect(() => {
        const currentJob = jobTitles[currentJobIndex];
        setHighlightedSkills(currentJob.relevantSkills);
        setRelevantExperience(currentJob.relevantExperience);
    }, [currentJobIndex]);

    return (
        <div className="relative min-h-screen overflow-hidden">
            {/* <div className="absolute inset-0 bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 opacity-5" /> */}

            <div className="max-w-7xl mx-auto px-4 py-24 overflow-x-hidden">
                <div className="grid lg:grid-cols-2 gap-12 items-center">
                    {/* Left column - Benefits */}
                    <div className="space-y-12">
                        <div className="space-y-4">
                            <h2 className="text-4xl font-bold tracking-tight">
                                Transform Your {industry} Career with AI-Powered CVs
                            </h2>
                            <p className="text-lg text-muted-foreground">
                                Stand out in the competitive {jobTitle.toLowerCase()} job market
                                with perfectly tailored resumes.
                            </p>
                        </div>

                        <div className="grid gap-6">
                            <Card className="p-6 transition-all hover:shadow-lg">
                                <div className="flex gap-4">
                                    <div className="mt-1">
                                        <Bot className="w-6 h-6 text-pink-600" />
                                    </div>
                                    <div>
                                        <h3 className="font-semibold mb-2">
                                            AI-Powered Precision for Your {jobTitle} CV
                                        </h3>
                                        <p className="text-muted-foreground">
                                            No more guessing which skills to include—our AI analyzes
                                            job descriptions and ensures your expertise gets the
                                            spotlight.
                                        </p>
                                    </div>
                                </div>
                            </Card>

                            <Card className="p-6 transition-all hover:shadow-lg">
                                <div className="flex gap-4">
                                    <div className="mt-1">
                                        <Filter className="w-6 h-6 text-purple-600" />
                                    </div>
                                    <div>
                                        <h3 className="font-semibold mb-2">Crush ATS Filters</h3>
                                        <p className="text-muted-foreground">
                                            TailoredCV.ai ensures your CV is keyword-optimized to
                                            pass ATS filters, so it lands in front of recruiters
                                            every time.
                                        </p>
                                    </div>
                                </div>
                            </Card>

                            <Card className="p-6 transition-all hover:shadow-lg">
                                <div className="flex gap-4">
                                    <div className="mt-1">
                                        <Code className="w-6 h-6 text-blue-600" />
                                    </div>
                                    <div>
                                        <h3 className="font-semibold mb-2">
                                            Showcase In-Demand Skills
                                        </h3>
                                        <p className="text-muted-foreground">
                                            Our AI emphasizes the skills most relevant to your{" "}
                                            {jobTitle} applications, giving you a competitive edge.
                                        </p>
                                    </div>
                                </div>
                            </Card>

                            <Card className="p-6 transition-all hover:shadow-lg">
                                <div className="flex gap-4">
                                    <div className="mt-1">
                                        <Clock className="w-6 h-6 text-pink-600" />
                                    </div>
                                    <div>
                                        <h3 className="font-semibold mb-2">
                                            Save Time. Apply Faster. Get More Interviews.
                                        </h3>
                                        <p className="text-muted-foreground">
                                            Create tailored resumes in minutes so you can focus on
                                            applying and interviewing instead of editing. Never miss
                                            another opportunity.
                                        </p>
                                    </div>
                                </div>
                            </Card>

                            <Card className="p-6 transition-all hover:shadow-lg">
                                <div className="flex gap-4">
                                    <div className="mt-1">
                                        <Layout className="w-6 h-6 text-purple-600" />
                                    </div>
                                    <div>
                                        <h3 className="font-semibold mb-2">
                                            Professional Templates Built for {industryTitles}
                                        </h3>
                                        <p className="text-muted-foreground">
                                            Choose from clean, modern templates that show off your
                                            experience and skills in a way that's easy for
                                            recruiters to read.
                                        </p>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>

                    {/* Right column - Resume Animation */}
                    <div className="lg:ml-12 overflow-x-hidden">
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6 }}
                            className="bg-background rounded-xl shadow-2xl overflow-x-hidden"
                        >
                            <Card className="w-full max-w-2xl mx-auto">
                                <CardContent className="p-6 space-y-6">
                                    {/* Header with job title and progress */}
                                    <div className="flex items-center justify-between mb-4">
                                        <Badge variant="secondary" className="text-lg py-1.5 px-4">
                                            {jobTitles[currentJobIndex].title}
                                        </Badge>
                                        <div className="flex items-center gap-2">
                                            {jobTitles.map((_, index) => (
                                                <motion.div
                                                    key={index}
                                                    className="relative h-2 w-2"
                                                >
                                                    <motion.div
                                                        className={`absolute inset-0 rounded-full ${
                                                            index === currentJobIndex
                                                                ? "bg-blue-500"
                                                                : "bg-gray-200"
                                                        }`}
                                                        initial={false}
                                                        animate={{
                                                            scale:
                                                                index === currentJobIndex
                                                                    ? [1, 1.2, 1]
                                                                    : 1,
                                                        }}
                                                        transition={{
                                                            duration: 0.5,
                                                            repeat:
                                                                index === currentJobIndex
                                                                    ? Number.POSITIVE_INFINITY
                                                                    : 0,
                                                            repeatType: "reverse",
                                                        }}
                                                    />
                                                </motion.div>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Progress bar */}
                                    <div className="relative h-1 bg-gray-100 rounded-full overflow-hidden w-full">
                                        <motion.div
                                            className="absolute inset-y-0 left-0 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full"
                                            initial={{ width: 0 }}
                                            animate={{ width: `${progress}%` }}
                                            transition={{ duration: 0.1, ease: "linear" }}
                                        />
                                    </div>

                                    {/* Personal Information */}
                                    <div className="flex items-center space-x-4 p-4 bg-gray-50 rounded-lg">
                                        <Avatar className="w-16 h-16">
                                            <AvatarImage
                                                src={resumeData.avatar}
                                                alt={resumeData.name}
                                            />
                                            <AvatarFallback>
                                                {resumeData.name
                                                    .split(" ")
                                                    .map((n) => n[0])
                                                    .join("")}
                                            </AvatarFallback>
                                        </Avatar>
                                        <div className="flex-1">
                                            <h2 className="text-xl font-bold">{resumeData.name}</h2>
                                            <h3 className="text-gray-600 mb-2">
                                                {jobTitles[currentJobIndex].title}
                                            </h3>
                                            <div className="md:flex gap-4 text-sm text-gray-600">
                                                <div className="flex items-center gap-1">
                                                    <Mail className="w-4 h-4" />
                                                    <span>{resumeData.email}</span>
                                                </div>
                                                <div className="flex items-center gap-1">
                                                    <Phone className="w-4 h-4" />
                                                    <span>{resumeData.phone}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Experience Section */}
                                    <div className="space-y-4">
                                        <h4 className="font-semibold text-lg flex items-center gap-2">
                                            <Briefcase className="w-5 h-5" />
                                            Experience
                                        </h4>
                                        <motion.div layout className="space-y-4">
                                            {resumeData.experience.map((exp, index) => (
                                                <motion.div
                                                    key={index}
                                                    layout
                                                    animate={{
                                                        height: relevantExperience.includes(
                                                            exp.title,
                                                        )
                                                            ? "auto"
                                                            : "fit-content",
                                                        backgroundColor:
                                                            relevantExperience.includes(exp.title)
                                                                ? "rgb(239 246 255)"
                                                                : "transparent",
                                                    }}
                                                    className="border p-4 rounded-lg shadow-sm transition-colors duration-300"
                                                >
                                                    <motion.h5
                                                        layout
                                                        className="font-semibold text-blue-600"
                                                    >
                                                        {exp.title}
                                                    </motion.h5>
                                                    <motion.p layout className="text-gray-600">
                                                        {exp.company} | {exp.period}
                                                    </motion.p>
                                                    <AnimatePresence>
                                                        {relevantExperience.includes(exp.title) && (
                                                            <motion.p
                                                                initial={{ opacity: 0, height: 0 }}
                                                                animate={{
                                                                    opacity: 1,
                                                                    height: "auto",
                                                                }}
                                                                exit={{ opacity: 0, height: 0 }}
                                                                transition={{ duration: 0.3 }}
                                                                className="mt-2 text-gray-700"
                                                            >
                                                                {exp.description}
                                                            </motion.p>
                                                        )}
                                                    </AnimatePresence>
                                                </motion.div>
                                            ))}
                                        </motion.div>
                                    </div>

                                    {/* Skills Section */}
                                    <div className="space-y-4">
                                        <h4 className="font-semibold text-lg flex items-center gap-2">
                                            <GraduationCap className="w-5 h-5" />
                                            Skills
                                        </h4>
                                        <motion.div layout className="grid grid-cols-2 gap-4">
                                            {resumeData.skills.map((skill, index) => (
                                                <motion.div
                                                    key={index}
                                                    layout
                                                    className="flex flex-col"
                                                    initial={{ opacity: 0, y: 20 }}
                                                    animate={{
                                                        opacity: 1,
                                                        y: 0,
                                                        scale: highlightedSkills.includes(
                                                            skill.name,
                                                        )
                                                            ? 1.05
                                                            : 1,
                                                    }}
                                                    transition={{
                                                        duration: 0.5,
                                                        delay: index * 0.1,
                                                    }}
                                                >
                                                    <span
                                                        className={`text-sm font-medium ${highlightedSkills.includes(skill.name) ? "text-blue-600" : "text-gray-700"}`}
                                                    >
                                                        {skill.name}
                                                    </span>
                                                    <div className="relative  h-1.5 bg-gray-100 rounded-full overflow-hidden">
                                                        <motion.div
                                                            className={`absolute inset-y-0 left-0 ${
                                                                highlightedSkills.includes(
                                                                    skill.name,
                                                                )
                                                                    ? "bg-gradient-to-r from-blue-500 to-purple-500"
                                                                    : "bg-gray-300"
                                                            }`}
                                                            initial={{ width: 0 }}
                                                            animate={{ width: `${skill.level}%` }}
                                                            transition={{ duration: 0.5 }}
                                                        />
                                                    </div>
                                                </motion.div>
                                            ))}
                                        </motion.div>
                                    </div>
                                </CardContent>
                            </Card>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
}
