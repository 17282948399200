import { Card, CardContent } from "@/components/ui/card";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Quote } from "lucide-react";

type Testimonial = {
    quote: string;
    name: string;
    title: string;
    initials: string;
};

const testimonials = [
    {
        quote: "TailoredCV.ai made my job search so much easier. The AI found keywords I would have missed, and I started getting callbacks right away.",
        name: "Maria P.",
        // title: "Software Engineer",
        initials: "MP",
    },
    {
        quote: "I used TailoredCV.ai to apply for multiple roles—each with a customized CV. It saved me hours, and the results were incredible.",
        name: "Angela I.",
        // title: "Software Engineer",
        initials: "AI",
    },
    {
        quote: "As a career changer, TailoredCV.ai helped me highlight my transferable skills effectively. I landed my dream job in tech!",
        name: "Filip P.",
        // title: "UX Designer",
        initials: "FP",
    },
    {
        quote: "The AI-powered insights helped me tailor my CV for each application. My interview rate increased significantly!",
        name: "David G.",
        // title: "Data Analyst",
        initials: "DG",
    },
];

export default function ConversionTestimonials() {
    return (
        <div className="relative">
            {/* Gradient background with opacity */}
            {/* <div className="absolute inset-0 bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 opacity-5" /> */}

            {/* Content */}
            <div className="relative py-12 px-4">
                <div className="max-w-6xl mx-auto">
                    <div className="text-center mb-10">
                        <h2 className="text-3xl font-bold bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 bg-clip-text text-transparent">
                            What Our Users Say
                        </h2>
                    </div>
                    <div className="grid md:grid-cols-2 gap-8">
                        {testimonials.map((testimonial, index) => (
                            <Card
                                key={index}
                                className="relative overflow-hidden transition-all hover:shadow-lg bg-white/50 backdrop-blur-sm"
                            >
                                <CardContent className="p-6">
                                    <Quote className="w-10 h-10 text-purple-600/20 mb-4" />
                                    <blockquote className="text-lg mb-4 text-muted-foreground">
                                        "{testimonial.quote}"
                                    </blockquote>
                                    <div className="flex items-center gap-4">
                                        <Avatar>
                                            <AvatarFallback className="bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 text-white">
                                                {testimonial.initials}
                                            </AvatarFallback>
                                        </Avatar>
                                        <div>
                                            <div className="font-semibold">{testimonial.name}</div>
                                            {/* <div className="text-sm text-muted-foreground">
                                                {testimonial.title}
                                            </div> */}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
